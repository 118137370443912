/* Add this to your CSS or as inline styles in ModalForm.js */
.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-content {
	background-color: #fff;
	padding: 20px;
	border-radius: 5px;
	width: 70%;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	border-radius: 10px;
	max-width: 700px;
	padding-bottom: 50px;
	gap: 20px;
}
textarea {
	padding: 1rem;
	font-size: 1rem;
}
