


.box-label-text {

/* Body/16px/Regular */
font-family: 'Helvetica';
font-style: normal;
font-weight: 400;
color: #3A3A3A;
/* Inside auto layout */
flex: none;
order: 0;
flex-grow: 0;
}

.checkbox-text{
        /* It took me more than 30 min to get to the job */
    font-style: normal;
    font-weight: 400;
    /* DLTS_Text color #3A3A3A */
    color: #3A3A3A;
    /* Inside auto layout */
    flex: none;
    order: 1;
    flex-grow: 0;
}

p{
    font-family: 'Helvetica';
}



.center-input {
    text-align: center;
  }
  
  .center-input ::-webkit-input-placeholder {
    text-align: center;
  }
  
  .center-input :-moz-placeholder {
    text-align: center;
  }