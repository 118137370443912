.header-text {
	font-family: "Sen", sans-serif;
	font-style: normal;
	font-weight: 800;
	text-align: center;
	color: #2d3c47;
	flex: none;
	order: 1;
	flex-grow: 0;
}

#submit-button,
#login-button,
#forgot-password-button,
#verify-code-button,
#change-password-button,
#success-login-button,
#back-home {
	/* button */

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 137px;
	gap: 10px;

	/* DLTS_Orange #EA710B */
	background: #ea710b;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 8;
	align-self: stretch;
	flex-grow: 0;

	/* Submit */

	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	line-height: 18px;
	text-align: center;
	text-transform: uppercase;

	/* White #FFFFFF */
	color: #ffffff;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
}

.grey-link-text {
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	/* identical to box height, or 125% */
	text-align: center;
	text-decoration-line: underline;
	/* DLTS_Dark #2d3c47 */
	color: #2d3c47;
	/* Inside auto layout */
	flex: none;
	order: 3;
	flex-grow: 0;
}

.grey-text {
	/* Body/16px/Regular */
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	/* identical to box height, or 125% */
	text-align: center;
	/* DLTS_Dark #2d3c47 */
	color: #2d3c47;
}

.orange-text {
	/* Link */
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* identical to box height, or 125% */
	text-align: center;
	text-decoration-line: underline;
	/* DLTS_Orange #EA710B */
	color: #ea710b;
}

.icon,
.text {
	vertical-align: middle;
	display: inline-block;
}

.light-grey-text {
	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	text-align: center;
	color: #2d3c47;
	mix-blend-mode: normal;
	opacity: 0.7;
}

.timepicker-input {
	width: 100%;
	font: inherit;
	letter-spacing: inherit;
	border: 0;
	box-sizing: content-box;
	background: none;
	height: 1.4375em;
	margin: 0;
	-webkit-tap-highlight-color: transparent;
	display: block;
	animation-name: mui-auto-fill-cancel;
	animation-duration: 10ms;
	padding: 16.5px 14px;
}

#bottomNav {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 1000;
}

.black-button {
	/* date */

	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 30px;

	/* DLTS_Dark #2d3c47 */
	background: #2d3c47;
	border-radius: 5px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	/* Body/16px/Regular */
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	/* identical to box height, or 125% */

	/* White #FFFFFF */
	color: #ffffff;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	border: none;
}

.grey-button {
	/* date */

	/* Auto layout */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 14px 20px;
	gap: 30px;

	/* DLTS_Light grey #F7F7F9 */
	background: #f7f7f9;
	border-radius: 5px;

	/* Inside auto layout */
	flex: none;
	order: 1;
	flex-grow: 0;

	/* Body/16px/Regular */
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
	/* identical to box height, or 125% */

	/* DLTS_Text color #3A3A3A */
	color: #3a3a3a;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	border: none;
}

.left-align {
	text-align: left;
}

.center-align {
	text-align: center;
}

.mb-25 {
	margin-bottom: 25px;
}

.hidden {
	display: none!important;
}

.calendar-day {
	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;

	/* identical to box height, or 133% */
	text-align: center;

	/* DLTS_Grey_text #696B7D */
	color: #696b7d;
}

#availability-submit-button {
	padding: 13px 100px;

	/* DLTS_Orange #EA710B */
	background: #ea710b;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	/* White #FFFFFF */
	color: #ffffff;
}

#availability-cancel-button {
	/* button */
	margin-top: 20px;
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 100px;
	gap: 10px;

	/* DLTS_Dark #2d3c47 */
	border: 1px solid #2d3c47;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 1;
	flex-grow: 0;

	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	/* DLTS_Text color #3A3A3A */
	color: #3a3a3a;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
}

#project-details-modal-button {
	padding: 13px 100px;

	/* DLTS_Orange #EA710B */
	background: #ea710b;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	font-family: 'Helvetica';
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	/* White #FFFFFF */
	color: #ffffff;
}

#delete-traineeform-submit-button {
	padding: 13px 100px;

	/* DLTS_Orange #EA710B */
	background: #ea710b;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	/* White #FFFFFF */
	color: #ffffff;
}

#availability-cancel-button {
	/* button */
	margin-top: 20px;
	box-sizing: border-box;

	/* Auto layout */
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 13px 100px;
	gap: 10px;

	/* DLTS_Dark #2d3c47 */
	border: 1px solid #2d3c47;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 1;
	flex-grow: 0;

	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	/* DLTS_Text color #3A3A3A */
	color: #3a3a3a;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;
}

.loader-spinner {
	margin: auto;
	width: 50%;
	margin-left: 33%;
}

#traineeFile-submit-button,
#add-form-button {
	padding: 13px 80px;

	/* DLTS_Orange #EA710B */
	background: #ea710b;
	border-radius: 28px;

	/* Inside auto layout */
	flex: none;
	order: 0;
	flex-grow: 0;

	font-family: "Helvetica";
	font-style: normal;
	font-weight: 400;
	text-align: center;
	text-transform: uppercase;

	/* White #FFFFFF */
	color: #ffffff;
}

.text-center {
	text-align: center;
}

.not-found-image-block {
	justify-content: center;
	align-items: center;
	display: flex;
	position: relative;
	padding: 40px 10px 50px 10px;
}

.gear-1 {
	position: absolute;
	left: 0;
	bottom: 1rem;
}

.gear-2 {
	position: absolute;
	top: 0;
	left: 3rem;
}

.gear-3 {
	position: absolute;
	right: 0;
	bottom: 2rem;
}

.title-404 {
	color: #2d3c47;
	font-size: 28px;
	font-style: normal;
	font-weight: 900;
	line-height: 33px;
}

.subtitle-404 {
	color: #2d3c47;
	text-align: center;

	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
}

#back-home {
	padding: 7px 100px;
	text-decoration: none;
	color: #fff;
	text-align: center;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-transform: uppercase;
}

.react-datepicker {
	transform: scale(2);
}


.unavailable {
    background-color: #ff5757;
    border-radius: 0.3rem;
    color: #fff;
}
.unavailable:hover {
    background-color: #e44f4f;
}
.project {
    background-color: #FFD866;
    border-radius: 0.3rem;
    color: #fff;
}
.project:hover {
    background-color: #e6c35b;
}
.projects {
    background-color: #EC7D31;
    border-radius: 0.3rem;
    color: #fff;
}
.projects:hover {
    background-color: #d06d2b;
}
.unavailable.project {
    background: linear-gradient(to bottom, #ff5757 50%, #FFD866  50%);
    border-radius: 0.3rem;
    color: #fff;
}
.unavailable.project:hover {
    background: linear-gradient(to bottom, #e44f4f 50%, #e6c35b  50%);
}
.unavailable.projects {
    background: linear-gradient(to bottom, #ff5757 50%, #EC7D31  50%);
    border-radius: 0.3rem;
    color: #fff;
	position: relative;
}
.unavailable.projects:hover {
    background: linear-gradient(to bottom, #e44f4f 50%, #d06d2b  50%);
}
.react-datepicker__day--in-range-end {
    background-color: #15507a !important;
}

.react-datepicker__day--range-end {
    color: #fff !important;
}
.react-datepicker__day--keyboard-selected {
    color: #fff !important;
	background-color: #1d5d90 !important;
	position: relative;
}
.react-datepicker__day--keyboard-selected .unavailable {
	background-color: transparent !important;
}

.react-datepicker__day--in-range .unavailable  {
		background-color: transparent!important;
}
.react-datepicker__day--in-range .project  {
		background-color: transparent!important;
}
.react-datepicker__day--in-range .projects  {
		background-color: transparent!important;
}
.react-datepicker__day--in-range .unavailable.project  {
		background: transparent!important;
}
.react-datepicker__day--in-range .unavailable.projects  {
		background: transparent!important;
}
.react-datepicker__day--keyboard-selected {
	background-color: #216ba5 !important;
}
.react-datepicker__day--keyboard-selected:hover {
	background-color: #1d5d90 !important;
}
.react-datepicker__day--in-selecting-range .unavailable {
	background-color: rgba(255,87,87,0.5);
}
.react-datepicker__day--in-selecting-range .project {
	background-color: rgba(255,215,102, 0.5);
}
.react-datepicker__day--in-selecting-range .projects {
	background-color: rgba(235,125,49, 0.5);
}
.react-datepicker__day--in-selecting-range .unavailable.project  {
	 background: linear-gradient(to bottom, rgba(255,87,87,0.5) 50%, rgba(255,215,102, 0.5)  50%);
}
.react-datepicker__day--in-selecting-range .unavailable.projects {
	background: linear-gradient(to bottom, rgba(255,87,87,0.5) 50%, rgba(235,125,49, 0.5)  50%);
}


.unavailable-icon,.project-icon,.projects-icon,.selected-icon,.unavailable-project-icon,.unavailable-projects-icon {
	width: 1rem;
	height: 1rem;
    background-color: #ff5757;
	border-radius: 4px;
	min-width: 1rem;
}
.project-icon {
    background-color: #FFD866;
}
.projects-icon {
    background-color: #EC7D31;
}
.selected-icon {
    background-color: #15507a;
}
.unavailable-project-icon {
	background-color: transparent;
    background: linear-gradient(to bottom, #ff5757 50%, #FFD866  50%);
}
.unavailable-projects-icon {
	background-color: transparent;
	background: linear-gradient(to bottom, #ff5757 50%, #EC7D31  50%);
}
.empty-icon {
	width: 1rem;
	height: 1rem;
	border-radius: 4px;
	border: 1px solid lightgrey;
	box-sizing: border-box;
}


.drop-backdrop {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 9;
}
.drop-list {
	margin-top: 4px;
	position: absolute;
	z-index: 1001;
	background-color: #fff;
	color: #3a3a3a;
	box-shadow: 1px 1px 6px grey;
	padding-top: 2px;
	padding-bottom: 3px;
	border-radius: 6px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	-webkit-user-select: none; /* Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; 
	/* transform: scale(0.55); */
}
.drop-list.left {
	right: 0;
}
.drop-list.right {
	left: 0;
}
.drop-list.bottom {
}
.drop-list.top {
	bottom: 2rem;
}
.drom-item {
	padding: 1px 5px;
	font-size: 1rem;
	width: 100%;
    text-align: start;
    box-sizing: border-box;
	font-size: 10px;
	display: flex;
	flex-direction: row;
	gap: 6px;
	align-items: center;
	line-height: 12px;
	z-index: 10;
}
.drom-item svg {
	width: 0.5em;
}
.drom-item:hover {
	color: #15507a;
}

@media screen and (max-width: 900px) {
	.react-datepicker {
		transform: scale(1.5);
	}
	.calendar-container {
		padding-top: 6rem !important;
	}
}
@media screen and (max-width: 700px) {
	.react-datepicker {
		transform: scale(1.3);
    }
}

.success_box {
	background-color: #a9ff93;
	border: 1px solid darkgreen;
	padding: 0.35rem 1rem;
	width: fit-content;
	border-radius: 0.25rem;
	color: green;
}

.error_box {
	background-color: #ff8484;
	border: 1px solid darkred;
	padding: 0.35rem 1rem;
	width: fit-content;
	border-radius: 0.25rem;
	color: darkred;
}

.contact-footer {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
}
@media (max-height: 600px) {
  .contact-footer {
    position: static;
    transform: translateX(0);
  }
}
